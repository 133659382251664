import { getExistingCart } from './checkout-queries'
import {
  createCart,
  updateItemQuantity,
  addItem,
  removeItem,
} from './checkout-mutations'
import { storefrontClient } from './store-context'

export const createNewCart = async () => {
  const { data, errors } = await storefrontClient.request(createCart, {
    variables: {
      cartInput: {
        lines: [],
      },
      buyerIdentity: {
        countryCode: 'CA',
      },
    },
  })

  if (errors) {
    console.error(errors)
    return null
  }

  return data.cartCreate.cart
}

export const getExistingCheckoutCart = async (cartId: string) => {
  const { data, errors } = await storefrontClient.request(getExistingCart, {
    variables: {
      cartId: cartId,
    },
  })

  if (errors) {
    console.error(errors)
    return null
  }

  return data.cart
}
export const addItemToCart = async (
  cartId: string,
  merchandiseId: string,
  quantity: number
) => {
  const { data, errors } = await storefrontClient.request(addItem, {
    variables: {
      cartId: cartId,
      lines: [
        {
          quantity,
          merchandiseId,
        },
      ],
    },
  })

  if (errors) {
    console.error(errors)
    return null
  }

  return data.cartLinesAdd.cart
}

export const removeItemFromCart = async (cartId: string, lineId: string) => {
  const { data, errors } = await storefrontClient.request(removeItem, {
    variables: {
      cartId: cartId,
      lineIds: [lineId],
    },
  })

  if (errors) {
    console.error(errors)
    return null
  }

  return data.cartLinesRemove.cart
}

export const updateCartItemQuantity = async (
  cartId: string,
  lineId: string,
  merchandiseId: string,
  quantity: number
) => {
  const { data, errors } = await storefrontClient.request(updateItemQuantity, {
    variables: {
      cartId: cartId,
      updatedItems: [
        {
          id: lineId,
          quantity: quantity,
          merchandiseId: merchandiseId,
        },
      ],
    },
  })

  if (errors) {
    console.error(errors)
    return null
  }

  return data.cartLinesUpdate.cart
}
