exports.components = {
  "component---src-components-accessibility-tsx": () => import("./../../../src/components/accessibility.tsx" /* webpackChunkName: "component---src-components-accessibility-tsx" */),
  "component---src-components-blog-blog-post-tsx": () => import("./../../../src/components/blog/blogPost.tsx" /* webpackChunkName: "component---src-components-blog-blog-post-tsx" */),
  "component---src-components-press-press-post-tsx": () => import("./../../../src/components/press/pressPost.tsx" /* webpackChunkName: "component---src-components-press-press-post-tsx" */),
  "component---src-components-privacy-tsx": () => import("./../../../src/components/privacy.tsx" /* webpackChunkName: "component---src-components-privacy-tsx" */),
  "component---src-components-terms-tsx": () => import("./../../../src/components/terms.tsx" /* webpackChunkName: "component---src-components-terms-tsx" */),
  "component---src-pages-2025-international-year-of-quantum-tsx": () => import("./../../../src/pages/2025-international-year-of-quantum.tsx" /* webpackChunkName: "component---src-pages-2025-international-year-of-quantum-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-events-canadian-quantum-cup-tsx": () => import("./../../../src/pages/events/canadian-quantum-cup.tsx" /* webpackChunkName: "component---src-pages-events-canadian-quantum-cup-tsx" */),
  "component---src-pages-events-pennylane-research-retreat-tsx": () => import("./../../../src/pages/events/pennylane-research-retreat.tsx" /* webpackChunkName: "component---src-pages-events-pennylane-research-retreat-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photonics-tsx": () => import("./../../../src/pages/photonics.tsx" /* webpackChunkName: "component---src-pages-photonics-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-products-lightning-tsx": () => import("./../../../src/pages/products/lightning.tsx" /* webpackChunkName: "component---src-pages-products-lightning-tsx" */),
  "component---src-pages-products-pennylane-tsx": () => import("./../../../src/pages/products/pennylane.tsx" /* webpackChunkName: "component---src-pages-products-pennylane-tsx" */),
  "component---src-pages-products-strawberry-fields-tsx": () => import("./../../../src/pages/products/strawberry-fields.tsx" /* webpackChunkName: "component---src-pages-products-strawberry-fields-tsx" */),
  "component---src-pages-products-x-series-tsx": () => import("./../../../src/pages/products/x-series.tsx" /* webpackChunkName: "component---src-pages-products-x-series-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-residency-tsx": () => import("./../../../src/pages/residency.tsx" /* webpackChunkName: "component---src-pages-residency-tsx" */),
  "component---src-pages-shop-cart-tsx": () => import("./../../../src/pages/shop/cart.tsx" /* webpackChunkName: "component---src-pages-shop-cart-tsx" */),
  "component---src-pages-shop-products-tsx": () => import("./../../../src/pages/shop/products.tsx" /* webpackChunkName: "component---src-pages-shop-products-tsx" */),
  "component---src-pages-values-tsx": () => import("./../../../src/pages/values.tsx" /* webpackChunkName: "component---src-pages-values-tsx" */),
  "component---src-templates-shopify-product-shopify-product-tsx": () => import("./../../../src/templates/ShopifyProduct/ShopifyProduct.tsx" /* webpackChunkName: "component---src-templates-shopify-product-shopify-product-tsx" */)
}

