/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ILineItemProps } from '../components/Shop/LineItem/LineItem'
import * as React from 'react'
import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import {
  createNewCart,
  getExistingCheckoutCart,
  addItemToCart,
  removeItemFromCart,
  updateCartItemQuantity,
} from './checkout-actions'

export const storefrontClient = createStorefrontApiClient({
  storeDomain: String(process.env.GATSBY_MYSHOPIFY_URL),
  apiVersion: '2025-01',
  publicAccessToken: String(process.env.GATSBY_STOREFRONT_ACCESS_TOKEN),
})

const defaultValues = {
  cart: [],
  addVariantToCart: (_variantId: string, _quantity: string) => {},
  removeLineItem: (_checkoutId: string, _lineItemId: string) => {},
  updateLineItem: (
    _checkoutId: string,
    _lineItemId: string,
    _merchandiseId: string,
    _quantity: string
  ) => {},
  client: storefrontClient,
  checkout: {
    id: '',
    lines: { nodes: [] as ILineItemProps[] },
    cost: { totalAmount: { currencyCode: '', amount: '0' } },
    checkoutUrl: '',
  },
  loading: false,
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

interface ICheckout {
  lines: { nodes: ILineItemProps[] }
  id: string
  cost: { totalAmount: { currencyCode: string; amount: string } }
  checkoutUrl: string
}

export const StoreProvider = ({ children }: { children: JSX.Element }) => {
  const [checkout, setCheckout] = React.useState<ICheckout>(
    defaultValues.checkout
  )
  const [loading, setLoading] = React.useState(false)

  const setCheckoutItem = (checkout: ICheckout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }
    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutId && existingCheckoutId !== `null`) {
        try {
          const existingCheckout = await getExistingCheckoutCart(
            existingCheckoutId
          )

          if (existingCheckout) {
            setCheckoutItem(existingCheckout as ICheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, 'null')
        }
      }
      const newCheckout = await createNewCart()

      if (newCheckout) setCheckoutItem(newCheckout)
    }
    initializeCheckout()
  }, [])

  const addVariantToCart = async (merchandiseId: string, quantity: string) => {
    setLoading(true)
    const updatedCart = await addItemToCart(
      checkout.id,
      merchandiseId,
      parseInt(quantity, 10)
    )
    if (updatedCart) setCheckout(updatedCart)
    setLoading(false)
  }

  const removeLineItem = async (checkoutId: string, lineItemId: string) => {
    setLoading(true)
    const updatedCart = await removeItemFromCart(checkoutId, lineItemId)
    if (updatedCart) setCheckout(updatedCart)
    setLoading(false)
  }

  const updateLineItem = async (
    checkoutId: string,
    lineItemId: string,
    merchandiseId: string,
    quantity: string
  ) => {
    setLoading(true)
    const updatedCart = await updateCartItemQuantity(
      checkoutId,
      lineItemId,
      merchandiseId,
      parseInt(quantity, 10)
    )
    if (updatedCart) setCheckout(updatedCart)
    setLoading(false)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
