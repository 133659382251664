export const getExistingCart = `query getExistingCart($cartId: ID!) {
  cart(id: $cartId) {
    id
    checkoutUrl
      lines(first: 100) {
        nodes {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                url
              }
              price {
                amount
                currencyCode
              }
              product {
                handle
                title
              }
            }
          }
        }
      }
    cost {
      totalAmount {
        amount
        currencyCode
      }
    }
  }
}
`
